import { defineStore } from "pinia";
import type { LoyaltyLevelTier } from "~/types/User";
import { type SignInResult } from "~/lib/firebase";
import type { FavouriteRestaurant } from "~/types/Restaurant";
const useUserStore = defineStore("user", {
  state: () => {
    return {
      isLoading: false,
      id: 0,
      name: "",
      email: "",
      password: "",
      phone: "",
      phoneCode: "+66",
      phoneCodeCountry: "th",
      accessToken: "",
      flag: false,
      referralCode: "",
      points: 0,
      birthDay: "",
      avatarThumb: "",
      avatarOriginal: "",
      guestBookingIds: [] as string[],
      cleverTapObj: null as any,
      totalSpend: 0,
      totalReservation: 0,
      loyaltyLevel: "" as LoyaltyLevelTier,
      loyaltyLevelId: 0,
      topLoyaltyLevelId: 0,
      loyaltyLevelExpiryDate: "",
      loyaltyIconBadge: "",
      progressTotalReservations: 0,
      progressTotalSpend: 0,
      startDateLevel: "",
      addressList: [],
      refreshToken: "",
      userLocation: {
        lat: 0,
        lng: 0,
      },
      language: "",
      // for sign in with social media
      signInResult: null as SignInResult | null,
      showLoginModal: false,
      needInputPhone: false,
      needInputEmail: false,
      favouriteRestaurants: [] as FavouriteRestaurant,
      isPhoneVerified: false,
      dataVerification: {
        success: false,
        message: "",
        data: {
          otp: "",
          time: "",
        },
      },
      lat: 0,
      lng: 0,
    };
  },
  getters: {
    isUserSignedIn(): boolean {
      return !!this.accessToken;
    },
    fullPhoneNumber(): string {
      return `${this.phoneCode}${this.phone}`;
    },
    isUserFlagged(): boolean {
      return this.flag === true;
    },
    isUserHavePhone(): boolean {
      if (
        this.phone &&
        this.phone.length > 0 &&
        this.phoneCode &&
        this.phoneCode.length > 0
      ) {
        return true;
      }
      return false;
    },
    isHungryHubEmail(state) {
      if (typeof state.email !== "string" || state.email.length === 0) {
        return false;
      }
      return !!state.email.includes("@hungryhub.com");
    },
    favouriteRestaurantIds(state) {
      return state.favouriteRestaurants.map((favourite) => favourite.id);
    },
    guestUser(state) {
      return {
        name: state.name,
        email: state.email,
        phone: state.phone,
      };
    },
  },
  actions: {
    resetState() {
      this.name = "";
      this.email = "";
      this.password = "";
      this.phone = "";
      this.phoneCode = "+66";
      this.phoneCodeCountry = "th";
      this.accessToken = "";
      this.referralCode = "";
    },
  },
});

export default useUserStore;
